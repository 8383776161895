import {createRouter, createWebHistory} from 'vue-router'


const routes = [
    {path: '/', component: () => import('./components/Home.vue')},
    {path: '/r/:id', component: () => import('./components/conf/ConferenceView.vue')},
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})
export default router
