import {createI18n} from 'vue-i18n'
import {en} from "./en.ts";
import {ru} from "./ru.ts";

export const i18n = createI18n({
    locale: navigator.language,
    fallbackLocale: 'en',
    messages: {
        en: en,
        ru: ru
    }
})
