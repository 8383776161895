export const ru = {
  m: {
    createConference: "Создать конференцию",
    startConference: "Начать конференцию",
    conferenceLinkHint: "Ссылка на конференцию скопирована в буфер обмена.",
    mobileConferenceLinkHint: "Текущая адресная строка браузера является ссылкой на конференцию.",
    motivateHint: "Вы можете отправить её участникам, используя кнопку «Поделиться» в браузере."
  },
  access: {
    error: 'Нет доступа к камере/микрофону',
    request: 'Запросить доступ',
    suggest: 'Если клавиша запроса доступа не действует, проверьте, нет ли запрета в настройках браузера. После изменения настроек, рекомендуется перезапустить браузер и заново зайти на сайт.'
  }
}
