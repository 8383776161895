export const en = {
  m: {
    createConference: "Create conference",
    startConference: "Start conference",
    conferenceLinkHint: "The conference link has been copied to the clipboard.",
    motivateHint: "You can send it to participants using the share button in the browser."
  },
  access: {
    error: 'No access to camera/microphone',
    request: 'Request access',
    suggest: 'If the access request key does not work, check if there is a restriction in your browser settings. After changing the settings, it is recommended to restart the browser and re-enter the site.'
  }
}
